import ApiService from '@/services/api.service'
const OfferSerive={
    GetList(Search,Lang,SortColumn,OrderType,Offset,PageNumber,PageLimit,IsStartPageFromZero){
        return ApiService.get(`/Offer/Getlist?Search=${Search}&Lang=${Lang}&SortColumn=${SortColumn}&OrderType=${OrderType}&Offset=${Offset}&PageNumber=${PageNumber}&PageLimit=${PageLimit}&IsStartPageFromZero=${IsStartPageFromZero}`)
    },
    // GetList(data){
    //     return ApiService.post(`/Offer/GetList`,data)
    // },
    Get(id){
        return ApiService.get(`/Offer/Get?id=${id}`)
    },
    Update(data){
        return ApiService.post(`/Offer/Update`,data)
    },
    AddComment(id,commenttext){
        return ApiService.post(`/Offer/AddComment?id=${id}&commenttext=${commenttext}`)
    }
}
export default OfferSerive