<template>
  <div class="wrapper">
    <b-row>
      <b-col>
        <div class="m-4 pl-5">
            <div class="mt-4 pt-4">
            <p class="school32">{{$t('Taklif')}}</p>
            <p class="TextBlack17">{{ Offer.text }}</p>
            </div>
            <div>
              <b-row>
                <b-col> <p><svg class="mb-2 mr-2" xmlns="http://www.w3.org/2000/svg" width="20" height="25.999" viewBox="0 0 20 25.999">
                      <path id="Account_Icon" data-name="Account Icon" d="M-6204-2842a4.005,4.005,0,0,1-4-4,4,4,0,0,1,4-4h12a4,4,0,0,1,4,4,4,4,0,0,1-4,4Zm-2-4a2,2,0,0,0,2,2h12a2,2,0,0,0,2-2,2,2,0,0,0-2-2h-12A2,2,0,0,0-6206-2846Zm0-14a8.008,8.008,0,0,1,8-8,8.008,8.008,0,0,1,8,8,8.01,8.01,0,0,1-8,8A8.01,8.01,0,0,1-6206-2860Zm2,0a6.007,6.007,0,0,0,6,6,6.007,6.007,0,0,0,6-6,6.006,6.006,0,0,0-6-6A6.006,6.006,0,0,0-6204-2860Z" transform="translate(6208 2868)" fill="#ced5e0"/>
                    </svg>
                      {{ Offer.senderfio }}  </p></b-col>
                <b-col>
                  <p> <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20.649" viewBox="0 0 20 20.649">
                          <path id="Контур_530" data-name="Контур 530" d="M11.006,11.4a11.365,11.365,0,0,1-4.168-.739A9.4,9.4,0,0,1,3.584,8.57,9.4,9.4,0,0,1,1.469,5.3,11.574,11.574,0,0,1,.715,1.029,11.022,11.022,0,0,1,1.484-3.15,9.737,9.737,0,0,1,3.6-6.4,9.482,9.482,0,0,1,6.771-8.5a10.319,10.319,0,0,1,3.944-.746,11.818,11.818,0,0,1,4.123.683,9.1,9.1,0,0,1,3.153,1.922,8.436,8.436,0,0,1,2.015,2.978A10.111,10.111,0,0,1,20.715.186a9.407,9.407,0,0,1-.362,2.758,5.611,5.611,0,0,1-1,1.944,3.992,3.992,0,0,1-1.5,1.145,4.912,4.912,0,0,1-2.84.284,2.384,2.384,0,0,1-.825-.332,2.236,2.236,0,0,1-.642-.646,2.865,2.865,0,0,1-.4-1.026A4.285,4.285,0,0,1,11.913,5.85a3.3,3.3,0,0,1-2.034.567,3.838,3.838,0,0,1-3-1.272,5.535,5.535,0,0,1-1.142-3.8V.753A5.5,5.5,0,0,1,6.861-3.038,3.8,3.8,0,0,1,9.812-4.277a4,4,0,0,1,1.12.142,3.261,3.261,0,0,1,.847.373,2.5,2.5,0,0,1,.608.537,2.607,2.607,0,0,1,.381.627v-1.47H15V2.253a3.368,3.368,0,0,0,.276,1.653.927.927,0,0,0,.836.429,2.372,2.372,0,0,0,1-.205,1.825,1.825,0,0,0,.765-.694,3.9,3.9,0,0,0,.493-1.284A9.316,9.316,0,0,0,18.543.186,8.1,8.1,0,0,0,17.924-3.1a6.607,6.607,0,0,0-1.686-2.343,7.015,7.015,0,0,0-2.493-1.4,9.752,9.752,0,0,0-3.03-.463,8.162,8.162,0,0,0-3.108.582A7.23,7.23,0,0,0,5.129-5.068,7.594,7.594,0,0,0,3.487-2.449a9.639,9.639,0,0,0-.593,3.478A10.061,10.061,0,0,0,3.48,4.577,7.491,7.491,0,0,0,5.133,7.23,7.058,7.058,0,0,0,7.7,8.891a9.217,9.217,0,0,0,3.31.571,11.089,11.089,0,0,0,3.037-.354,8.9,8.9,0,0,0,2.045-.84v2.1a8.9,8.9,0,0,1-2.064.742A13.237,13.237,0,0,1,11.006,11.4Zm-.694-7.06a2.319,2.319,0,0,0,1.746-.687A2.549,2.549,0,0,0,12.722,1.8V.327a3.017,3.017,0,0,0-.175-1.06,2.306,2.306,0,0,0-.489-.8,2.091,2.091,0,0,0-.761-.5A2.7,2.7,0,0,0,10.3-2.21a2.024,2.024,0,0,0-1.627.7,3.566,3.566,0,0,0-.6,2.314v.53a3.537,3.537,0,0,0,.6,2.3A2.031,2.031,0,0,0,10.312,4.342Z" transform="translate(-0.715 9.247)" fill="#ced5e0"/>
                        </svg>
                        {{Offer.senderemail}}</p>
                </b-col>
                <b-col >
                  <p> <svg class="mb-1 mr-2" xmlns="http://www.w3.org/2000/svg" width="24.001" height="16.001" viewBox="0 0 24.001 16.001">
                          <path id="View_Icon" data-name="View Icon" d="M-6148-2420a10.1,10.1,0,0,1-4.67-1.25,18.061,18.061,0,0,1-3.814-2.75,25.529,25.529,0,0,1-3.515-4,25.416,25.416,0,0,1,3.515-4,18.051,18.051,0,0,1,3.814-2.75A10.1,10.1,0,0,1-6148-2436a10.1,10.1,0,0,1,4.671,1.25,18.048,18.048,0,0,1,3.814,2.75,25.531,25.531,0,0,1,3.516,4,25.427,25.427,0,0,1-3.516,4,18.054,18.054,0,0,1-3.814,2.75A10.1,10.1,0,0,1-6148-2420Zm0-12a4,4,0,0,0-4,4,4,4,0,0,0,4,4,4,4,0,0,0,4-4A4,4,0,0,0-6148-2432Z" transform="translate(6160 2436)" fill="#ced5e0"/>
                        </svg> {{ Offer.viewcount }}</p>
                </b-col>
              </b-row>
              <!-- <b-row>

                <b-col>
                  <p> <svg class="mb-1 mr-2" xmlns="http://www.w3.org/2000/svg" width="24.001" height="15.999" viewBox="0 0 24.001 15.999">
                          <path id="Comment_Icon" data-name="Comment Icon" d="M-6185-2852h-17a6.007,6.007,0,0,1-6-6v-4a6.007,6.007,0,0,1,6-6h12a6.007,6.007,0,0,1,6,6v9A1,1,0,0,1-6185-2852Zm-18.5-6a.5.5,0,0,0-.5.5.5.5,0,0,0,.5.5h15a.5.5,0,0,0,.5-.5.5.5,0,0,0-.5-.5Zm0-5a.5.5,0,0,0-.5.5.5.5,0,0,0,.5.5h15a.5.5,0,0,0,.5-.5.5.5,0,0,0-.5-.5Z" transform="translate(6208 2868)" fill="#ced5e0"/>
                        </svg> {{$t('Izoh')}}</p>
                </b-col>
              </b-row> -->
            </div>
       </div>
      </b-col>
       <div class="linevartical"></div>
      <b-col>
         <div class="m-4 pl-5 pr-5 ">
          <div  @click="OpenModal"  class="mt-4 pt-4">
          <p class="school32"> {{$t('Comment')}} ... <svg class="mb-1 mr-2" xmlns="http://www.w3.org/2000/svg" width="18" height="18.414" viewBox="0 0 18 18.414">
              <path id="Контур_520" data-name="Контур 520" d="M64,16l-8,8v8h8l8-8-8-8" transform="translate(-55 -14.586)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            </svg></p>
            </div>
        <b-card v-for="(item,index) in Offer.Tables" :key="index" class="commentcard mb-4">
            <b-card-text>
              <p>
               {{item.text}}
              </p>
            </b-card-text>
            <b-row>
              <b-col>
                <p><svg class="mb-2 mr-2" xmlns="http://www.w3.org/2000/svg" width="20" height="25.999" viewBox="0 0 20 25.999">
                  <path id="Account_Icon" data-name="Account Icon" d="M-6204-2842a4.005,4.005,0,0,1-4-4,4,4,0,0,1,4-4h12a4,4,0,0,1,4,4,4,4,0,0,1-4,4Zm-2-4a2,2,0,0,0,2,2h12a2,2,0,0,0,2-2,2,2,0,0,0-2-2h-12A2,2,0,0,0-6206-2846Zm0-14a8.008,8.008,0,0,1,8-8,8.008,8.008,0,0,1,8,8,8.01,8.01,0,0,1-8,8A8.01,8.01,0,0,1-6206-2860Zm2,0a6.007,6.007,0,0,0,6,6,6.007,6.007,0,0,0,6-6,6.006,6.006,0,0,0-6-6A6.006,6.006,0,0,0-6204-2860Z" transform="translate(6208 2868)" fill="#ced5e0"/>
                </svg>
                  {{ item.senderfio }}  </p>
              </b-col>
              <b-col>
                 <p> <svg class="mb-2 mr-2" xmlns="http://www.w3.org/2000/svg" width="24" height="23.002" viewBox="0 0 24 23.002">
                  <path id="Контур_529" data-name="Контур 529" d="M-6388-4878h-16a4.007,4.007,0,0,1-4-4v-11a4.006,4.006,0,0,1,4-4v-3a1,1,0,0,1,1-1,1,1,0,0,1,1,1v3h12v-3a1,1,0,0,1,1-1,1,1,0,0,1,1,1v3a4.005,4.005,0,0,1,4,4v11A4.006,4.006,0,0,1-6388-4878Zm-3-8a1,1,0,0,0-1,1v2a1,1,0,0,0,1,1h2a1,1,0,0,0,1-1v-2a1,1,0,0,0-1-1Zm-6,0a1,1,0,0,0-1,1v2a1,1,0,0,0,1,1h2a1,1,0,0,0,1-1v-2a1,1,0,0,0-1-1Zm-6,0a1,1,0,0,0-1,1v2a1,1,0,0,0,1,1h2a1,1,0,0,0,1-1v-2a1,1,0,0,0-1-1Zm12-7a1,1,0,0,0-1,1v2a1,1,0,0,0,1,1h2a1,1,0,0,0,1-1v-2a1,1,0,0,0-1-1Zm-6,0a1,1,0,0,0-1,1v2a1,1,0,0,0,1,1h2a1,1,0,0,0,1-1v-2a1,1,0,0,0-1-1Zm-6,0a1,1,0,0,0-1,1v2a1,1,0,0,0,1,1h2a1,1,0,0,0,1-1v-2a1,1,0,0,0-1-1Z" transform="translate(6408 4901)" fill="#ced5e0"/>
                </svg>
                {{ item.date }}</p>
              </b-col>
            </b-row>
        </b-card>
         </div>
      </b-col>
    </b-row>
      <b-row class="mt-1">
        <b-col>
         
            <b-modal
             class="modal-content"
              hide-header
              size="md"
              v-model="ModalShow"
              no-close-on-backdrop
              hide-footer
            >
              <div>
                <p class="TextBlack32">{{$t('write')}}</p>
              </div>
                  <div class="mb-2">
                  <date-picker
                    v-model="OfferComment.date"
                    size="sm"
                    style="width:100%;"
                    :placeholder="$t('OnDate')"
                    format="DD.MM.YYYY"
                  ></date-picker>
              </div>
              <div>
                <b-form-textarea
                class="inputTaklif"
                  v-model="OfferComment.text"
                  rows="8"
                  max-rows="16"
                  :placeholder="$t('Таклиф')"
                ></b-form-textarea>
              </div>

              <b-row>
                <b-col class="text-right mt-3">
                  <p
                    class="mr-2"
                    @click="ModalShow = false"
                    size="sm"
                  >
                    {{ $t("Back") }}
                  </p>
                </b-col>
                <b-col class="text-left mt-3">
                  <b-button @click="send" size="sm" class="buttonTaklif">
                   
                    {{ $t("send") }}
                  </b-button>
                </b-col>
              </b-row>
            </b-modal>
         
        </b-col>
      </b-row>
    </div>
   
 
</template>
<script>
import OfferService from "@/services/Offer.service";
import RegionService from "@/services/Region.service";
export default {
  data() {
    return {
      ModalShow:false,
      modalComment: false,
      fields: [
        { key: "date", label: this.$t("Vaqt") },
        { key: "text", label: this.$t("Izoh") },
        
      ],
      tabrow: {
        id: 0,
        ownerid: 0,
        dat: "",
        text: "",
        Status: 1,
      },
      filter: {
        id:0,
        commenttext: "",
      },
      Offer: {
        senderphone: "+998",
      },
      OfferComment:{},
      regionlist: [],
      SaveLoading: false,
      editedIndex1: -1,
    };
  },
  created() {
    OfferService.Get(this.$route.params.id).then((res) => {
      this.Offer = res.data;
    });
    RegionService.GetAll().then((res) => {
      this.regionlist = res.data;
    });
  },
  methods: {
     Comment(item) {
      this.$router.push({
        name: "show",
        params: { id: item.id },
      });
     
    },
    OpenModal() {
      this.ModalShow = true;
      (this.tabrow = {
        id: 0,
        ownerid: 0,
        date: "",
        text: "",
        Status: 1,
      }),
        (this.editedIndex1 = -1);
    },
    RegionChange() {
      this.Offer.regionname = this.regionlist.filter(
        (item) => item.id == this.regionid
      )[0].name;
    },
    makeToast(message, title, type) {
      this.toastCount++;
      this.$bvToast.toast(message, {
        title: title,
        autoHideDelay: 2000,
        variant: type,
        solid: true,
      });
    },
    AddRow() {
      if (this.editedIndex1 > -1) {
        Object.assign(this.Offer.Tables[this.editedIndex1], this.tabrow);
      } else {
        this.Offer.Tables.push(this.tabrow);
      }
      this.ModalShow = false;
    },
    send() {
      OfferService.AddComment(this.filter.id = this.Offer.id,this.filter.commenttext = this.OfferComment.text)
        .then((res) => {
          //  this.makeToast(
          //   this.$t("SuccessMessage"),
          //   this.$t("message"),
          //   "success"
          // );
          this.ModalShow = false;
          this.$router.push({
            name: "taklif",
          });
          
        })
        .catch(() => {
          this.makeToast(
            error.response.data.error,
            this.$t("message"),
            "danger"
          );
          // swal("Oops", "Something went wrong!", "error");
        });
    },
    SaveData() {
      OfferService.Update(this.Offer)
        .then((res) => {
          // this.makeToast(
          //   this.$t("SuccessMessage"),
          //   this.$t("message"),
          //   "success"
          // );
          this.$router.push({
            name: "taklif",
          });
        })
        .catch((error) => {
          this.makeToast(
            error.response.data.error,
            this.$t("message"),
            "danger"
          );
        });
    },
  },
};
</script>
<style>

</style>
